import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`Badges are indicators of static information. They can be updated when a status changes, but they should not be actionable.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/badge/badge-1.svg",
      "alt": "banner guideline badge"
    }}></img>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Icon :`}</strong>{` to support the label on the badges either with icon.`}</li>
      <li parentName="ol"><strong parentName="li">{`Label :`}</strong>{` a text to provide context or information to the user. Works best when short.`}</li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Badges are typically used to reflect counts like number of objects, number of events, or number of unread items. If you need a selectable annotation consider using a label instead. They are often found in filter toggles to indicate the number of selections that have been made in a toolbar filter or select list.`}</p>
    <p><strong parentName="p">{`Common places badge appear:`}</strong>{` Card, Notification, Avatar, etc.`}</p>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li> Make attention to details and makes it easy to scan.</li>
      <li> To present information, such as status.</li>
      <li> Always position badge so that it’s clear to understand what object it’s related to.</li>
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li> If there’s an interaction (such as add/remove).</li>
      <li> If the entire message has an associated action.</li>
      <li> Don’t make badges clickable. Instead use button or chips component’s small variant.</li>
    </ul>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`A badge is used to annotate other information like a label or an object name. Badges are typically used to reflect counts, like number of unread notifications. Badges are not interactive. As a caution, badges can be styled using a variety of colors. Do not rely on color alone to communicate information because it causes barriers to access for many readers. For example, colorblind and low vision users may not be able to perceive the color differences, and screen readers do not announce colors to non-sighted readers.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do : Left Icon" caption="Use icon on the left only to provide information" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/badge/badge-2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't : Right Icon" caption="Use right icon unless there is an interaction" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/badge/badge-3.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Content`}</h2>
    <p>{`When creating badge :`}</p>
    <ul>
      <li parentName="ul">{`Use only two words if you need to describe a complex state.`}</li>
      <li parentName="ul">{`Don’t use special characters as part of badge text.`}</li>
      <li parentName="ul">{`Use past tense to describe the status of an action that is completed.`}</li>
      <li parentName="ul">{`Each badge should only indicate one status. Do not combine icons or text statuses into one badge.`}</li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do : With short copy" caption="Write short and meaningful label, they’re great for indicating status or how much of something is selected. But you can’t fit a lot into that space" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/badge/badge-4.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't : With long copy" caption="Write long and excessive label information. Badges are small and can’t contain much information" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/badge/badge-5.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      